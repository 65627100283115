export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';

export const filterOptions = [
  {
    key: 'getDocumentsForDashboard',
    text: 'All Documents',
    icon: 'sort',
    value: 'getDocumentsForDashboard',
  },
  {
    key: 'getVinelandDocumentsForDashboard',
    text: 'Vinelands',
    icon: 'google drive',
    value: 'getVinelandDocumentsForDashboard',
  },
  {
    key: 'getPdfCompDocumentsForDashboard',
    text: 'CAs',
    icon: 'google drive',
    value: 'getPdfCompDocumentsForDashboard',
  },
  {
    key: 'getReleaseDocumentsForDashboard',
    text: 'Releases',
    icon: 'calendar outline',
    value: 'getReleaseDocumentsForDashboard',
  },
  {
    key: 'getPdfReleaseDocumentsForDashboard',
    text: 'Releases',
    icon: 'google drive',
    value: 'getPdfReleaseDocumentsForDashboard',
  },
  {
    key: 'getCrisisDocumentsForDashboard',
    text: 'Crisis Plans',
    icon: 'calendar outline',
    value: 'getCrisisDocumentsForDashboard',
  },
  {
    key: 'getPdfCrisisDocumentsForDashboard',
    text: 'Crisis Plans',
    icon: 'google drive',
    value: 'getPdfCrisisDocumentsForDashboard',
  },
];
